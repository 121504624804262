/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { getHistory } from './utils/history.util';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export function createRootReducer(asyncReducers?: any): any {
  const sliceReducers = {
    toastr: toastrReducer,
    router: connectRouter(getHistory()),
    ...asyncReducers,
  };

  return combineReducers(sliceReducers);
}
