import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { ILogin, ILoginResponse } from '../../../models/login';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';
import { IGet } from '../../../models/get-all';
// import { Socket } from 'socket.io-client';
// import { CashierApiResponse } from '../../../models/cashier-response';

declare global {
  interface Window {
    LO: any;
    Usersnap: any;
  }
}

export const logLoginEventInLuckyOrange = (username: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Logged In', { 'Player Number': username });
      console.log('logged Login Event');
    }
  }
};

export const changeStateUsersnap = async (user: any) => {
  if (window.Usersnap != null && user?.username) {
    try {
      // await window.Usersnap.destroy();
      window.Usersnap.init({
        user: {
          username: user.username,
          email: `${user.username} / ${user.email}`,
        },
      });
    } catch (error) {
      console.error('UsernameError');
    }
  }
};

export const setLoginSession = (user: any) => {
  try {
    if (user.token) localStorage.setItem('token', user.token);
    if (user) localStorage.setItem('user', JSON.stringify(user));
    changeStateUsersnap(user);
    return true;
  } catch (e: any) {
    return false;
  }
};

const suffix = `/${userReducer.sliceName}/app`;

const LOGIN = `LOGIN${suffix}`;
export const loginAction = createAction<ILogin>(LOGIN);

const LOGIN_ERROR = `LOGIN_ERROR${suffix}`;
const loginErrorAction = createAction<string>(LOGIN_ERROR);
const LOGIN_SUCCESS = `LOGIN_SUCCESS${suffix}`;
const loginSuccessAction = createAction<any>(LOGIN_SUCCESS);

function* loginWatcher(): SagaIterator {
  yield takeEvery(LOGIN, loginWorker);
}
mergeSaga(loginWatcher);

function* loginWorker(action: any): SagaIterator {
  try {
    const response: IGet<ILoginResponse> = yield call(getApiPlayer, action.payload);
    const { data, message } = response;
    if (data.token) {
      setLoginSession(data);
      yield put(loginSuccessAction(response.data));
      logLoginEventInLuckyOrange(response.data.username);
    } else {
      yield put(loginErrorAction(message || 'Something went wrong.'));
    }
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(loginErrorAction('Login failed. Please make sure your login information is correct.'));
  }
}

const getApiPlayer = (data: ILogin): Request => {
  return vipApi(API_PATH.USER.LOGIN, 'post', {
    username: data.username,
    password: data.password,
    ip: data.ip,
    fingerprint: data.fingerprint,
    login_type: 3,
    site_id: data.site_id,
  });
};

const reduceHandlers = {
  [LOGIN]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    isLoggedIn: false,
  }),
  [LOGIN_SUCCESS]: (slice: UserSliceType, action: Action<ILoginResponse>): UserSliceType => {
    return {
      ...slice,
      loggedInUser: action.payload,
      isLoggedIn: true,
      isLoading: false,
      runTour: action.payload.tour_displayed == 0 ? true : false,
    };
  },
  [LOGIN_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    isLoggedIn: false,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
