import React, { useEffect } from 'react';
import ReduxToastr from 'react-redux-toastr';
import clsx from 'clsx';
import {
  // Dialog,
  // DialogContent,
  // DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Routes } from '../../routes';
import { IAppSettingsDto } from '../../models/common';
import { getAppSettings } from '../../utils';
import { getHistory } from '../../utils/history.util';

declare global {
  interface Window {
    LO: any;
  }
}

const appBarHeight = '0px';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    // backgroundColor: 'pink',
  },
  content: {
    marginTop: appBarHeight,
    marginBottom: appBarHeight,
  },
  marginTop72: {
    // marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-7px',
    },
  },
  // topScrollPaper: {
  //   alignItems: 'flex-start',
  // },
  // topPaperScrollBody: {
  //   verticalAlign: 'top',
  // },
}));

export const getCurrentPage = () => {
  return window.location.href.split(window.location.origin)[1].replace('/#/', '/');
};

export interface AppRootProps {
  isLoggedIn: boolean;
  app_settings: IAppSettingsDto;
}

export interface ExternalActionProps {
  getUserBalanceAction: () => void;
  getUserInfoAction: () => void;
  SetAppSettingAction: (appSettings: IAppSettingsDto) => void;
}

export const AppRoot = (props: AppRootProps & ExternalActionProps) => {
  const classes = useStyles();
  const { isLoggedIn, SetAppSettingAction, app_settings } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // const [open, setOpen] = React.useState(true);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  useEffect(() => {
    const local_host = window.location.origin;

    const host_name = local_host.replace('http://', '').replace('https://', '');

    const host = (getAppSettings().hosts ?? []).find((x: IAppSettingsDto) => x.site.url === host_name);
    if (host?.site?.name && host?.site?.url && host?.logo?.url && host?.theme?.url) {
      SetAppSettingAction(host);
    }

    if (params.auth) {
      getHistory().push(`/ext-auth?token=${params.auth}`);
    }

    window.addEventListener(
      'message',
      event => {
        if (event.data && event.data.data && event.data.action) {
          switch (event.data.action) {
            case 'set-height': {
              window.document.body.style.height = `${event.data.data.ht}px`;
              window.document.body.style.border = `0`;
              break;
            }
            default:
              break;
          }
        }
      },
      false,
    );
  }, []);

  useEffect(() => {
    if (app_settings?.theme?.url) {
      document.getElementById('css_url')?.setAttribute('href', app_settings.theme.url);
      document.getElementById('favicon_id')?.setAttribute('href', `//${app_settings.site.url}/${app_settings.logo.url}`);
    }
  }, [app_settings]);

  return (
    // <Dialog
    //   open={open}
    //   maxWidth={'sm'}
    //   fullWidth
    //   PaperProps={{ style: { backgroundColor: '#121212' } }}
    //   // classes={{
    //   //   scrollPaper: classes.topScrollPaper,
    //   //   paperScrollBody: classes.topPaperScrollBody,
    //   // }}
    // >
    //   <DialogTitle>Cashier</DialogTitle>
    //   <DialogContent dividers>
    <>
      {app_settings.site?.id > 0 && (
        <div className={classes.root}>
          <div
            className={clsx({
              [classes.content]: isLoggedIn,
            })}
          >
            <div
              className={clsx({
                [classes.marginTop72]: isLoggedIn,
              })}
            >
              <Routes />
            </div>
          </div>
          <ReduxToastr timeOut={2000} transitionIn='fadeIn' transitionOut='fadeOut' />
        </div>
      )}
    </>
    //   </DialogContent>
    // </Dialog>
  );
};
