import { connect } from 'react-redux';
import { isLoadingSelector, loggedInUserSelector, updateProfileStatusSelector } from '../../../api/user/user.selector';
import { ExternalActionProps, ExternalProps, UpdateUserProfileComponent } from './update-user-profile.component';
import { UpdateProfileAction } from '../../../api/user/actions/update-profile.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    updateProfileStatus: updateProfileStatusSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  UpdateProfileAction,
};

export const UpdateUserProfileContainer = connect(mapStateToProps, mapDispatchToProps)(UpdateUserProfileComponent);
