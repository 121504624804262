/**
 * Holds the application HashHistory or BrowserHistory
 */
import { createBrowserHistory } from 'history';
// import { createHashHistory } from 'history';

let history: any;

export function getHistory(): any {
  return history;
}

// export function setHistory(): any {
//   history = createHashHistory();
//   return history;
// }

export function setHistory(): any {
  history = createBrowserHistory();
  return history;
}
