import { connect } from 'react-redux';
import { isLoggedInSelector } from '../../api/user/user.selector';
import { AppRoot, AppRootProps, ExternalActionProps } from './app-root.component';
import { getUserBalanceAction } from './../../api/user/actions/get-balance.action';
import { getUserInfoAction } from '../../api/user/actions/get-user-info.action';
import { SetAppSettingAction } from '../../utils/redux/slice-app-settings/set-app-settings-action';
import { appSettingsSliceSelector } from '../../utils/redux/slice-app-settings/app-settings-selector';

function mapStateToProps(state: any): AppRootProps {
  return {
    isLoggedIn: isLoggedInSelector(state),
    app_settings: appSettingsSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getUserBalanceAction,
  getUserInfoAction,
  SetAppSettingAction,
};

export const AppRootContainer = connect(mapStateToProps, mapDispatchToProps)(AppRoot);
