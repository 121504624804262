import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, createStyles, Grid, Divider, Paper, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import 'react-intl-tel-input/dist/main.css';
import { getHistory } from '../../../utils/history.util';
import { FingerprintEnum } from '../../../enum/fingerprint/fingerprint';

export interface ExternalProps {
  isLoading: boolean;
  user: any;
  updateProfileStatus: boolean | null;
}

export interface ExternalActionProps {
  UpdateProfileAction: (user: any) => void;
}

//  #region styles

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'table',
      height: '100%',
      position: 'absolute',
      overflow: 'hidden',
      width: '100%',
      backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.jpg` + ')',
      backgroundSize: '1920px 1080px',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'black',
      backgroundPosition: 'top center',
      paddingBottom: '4rem',
    },
    helper: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    textPadding: {
      paddingTop: '8px',
      paddingBottom: '10px',
    },
    tabPanel: {
      display: 'flex',
      textAlign: 'center',
      padding: '0px',
      justifyContent: 'center',
    },
    hidden: {
      display: 'none!important',
    },
    bottomBtnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    successGrid: {
      marginTop: '0px',
      textAlign: 'center',
    },
    alert: {
      marginTop: '10px',
    },
    limitations: {
      display: 'inline-flex',
      textAlign: 'left',
    },
    alertTitle: {
      width: '100%',
      fontSize: '1.1rem',
      '& .MuiAlert-message': {
        width: '100%',
      },
      '& .MuiAlert-icon': {
        display: 'none',
      },
    },
    fwTextCenter: {
      width: ' 100%',
      textAlign: 'center',
      marginBottom: '10px',
    },
    successTextCenter: {
      width: '100%',
      color: 'rgb(199, 167, 99)',
      textAlign: 'center',
    },
    fw: {
      width: ' 100%',
    },
    UnLimitedOption: {
      fontSize: '20px',
      color: 'green',
      marginRight: '4px',
      textAlign: 'left',
    },
    LimitedOption: {
      fontSize: '15px',
      color: 'orange',
      marginRight: '4px',
    },
    buttonContinue: {
      backgroundColor: '#4caf50 !important',
      color: 'white !important',
      '&:hover': {
        backgroundColor: '#0080005c !important',
        color: 'white !important',
      },
    },
    noContinueButton: {
      color: 'orange !important',
      border: '1px solid #ffa50045',
    },
    divCenter: {
      marginBottom: '1rem',
      borderRadius: '5px',
      padding: '.5rem',
      width: '90%',
      margin: 'auto',
      textAlign: 'center',
    },
    orangeBox: {
      marginBottom: '1rem',
      borderRadius: '5px',
      padding: '.5rem',
      width: '90%',
      margin: 'auto',
      textAlign: 'center',
      border: '1px solid orange',
    },
    greenBox: {
      marginBottom: '1rem',
      border: '1px solid green',
      borderRadius: '5px',
      padding: '.5rem',
    },
    textPoints: {
      fontSize: '0.75rem !important',
    },
    successText: {
      textAlign: 'center',
      width: '100%',
      marginBottom: '1rem',
    },
    loginButton: {
      marginBottom: '1.5rem',
      paddingLeft: 0,
    },
    content: {
      margin: '0 auto',
      maxWidth: '450px',
      backgroundColor: '#242424',
    },
    header: {
      backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/auth-header-bg.png` + ')',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5px 5px 0px 0px',
    },
    main: {
      padding: '1.5rem',
    },
    shrinkLogo: {
      maxWidth: 100,
      height: 'auto',
      margin: '0px auto',
      display: 'block',
    },
    bottomText: {
      marginTop: 5,
      textAlign: 'center',
      color: '#eee',
    },
  }),
);

// #endregion styles

export const UpdateUserProfileComponent = (props: ExternalProps & ExternalActionProps) => {
  const { isLoading, user, UpdateProfileAction, updateProfileStatus } = props;

  const [userInfo, setUserInfo] = useState<any>({ ...user });

  const classes = useStyles();
  // const [phone, setPhone] = useState<string>('');
  const [phoneFullNumber] = useState<string>('');
  // const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  // const countryData: CountryData = {
  //   name: 'United States',
  //   areaCodes: null,
  //   dialCode: '1',
  //   iso2: 'us',
  //   priority: 0,
  // };

  useEffect(() => {
    // #region fingerprint
    if (localStorage.getItem('fpjs') == null) {
      FingerprintJS.load({ token: 'nvoiB2OqFxGoBIg53MC6' })
        .then(fp => fp.get())
        .then(result => {
          localStorage.setItem('fpjs', result.visitorId);
          setUserInfo({
            ...userInfo,
            fingerprint: result.visitorId,
          });
        })
        .catch(ex => {
          console.log(ex);
          localStorage.setItem('fpjs', FingerprintEnum.DEFAULT);
          setUserInfo({
            ...userInfo,
            fingerprint: FingerprintEnum.DEFAULT,
          });
        });
    }
    // #endregion fingerprint

    // #region ip
    fetch('https://api.ipify.org?format=json')
      .then(response => {
        return response.json();
      })
      .then(res => {
        setUserInfo({
          ...userInfo,
          ip: res.ip,
        });
      })
      .catch(err => console.error('Problem fetching my IP', err));
    // #endregion ip
  }, []);

  useEffect(() => {
    if (updateProfileStatus) {
      getHistory().push(`ext-login?username=${userInfo.username}&token=${localStorage.getItem('token')}`);
    }
  }, [updateProfileStatus]);

  // const handlePhoneNumber = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string) => {
  //   setPhone(value.replace(/\D/g, ''));
  //   setPhoneFullNumber(fullNumber);
  //   setIsValidPhoneNumber(isValid);

  //   setUserInfo({
  //     ...userInfo,
  //     phone: value.replace(/\D/g, ''),
  //     countryData: selectedCountryData,
  //   });
  // };

  const handleSave = () => {
    UpdateProfileAction({
      phoneFullNumber: phoneFullNumber,
      email: userInfo.email,
      phone: userInfo.phone,
      countryData: userInfo.countryData,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      password: userInfo.password,
      password_confirmation: userInfo.password_confirmation,
      dob: userInfo.dob,
    });
  };

  return (
    <>
      {userInfo && (
        <div className={classes.container}>
          <div className={classes.helper}>
            <Paper className={classes.content} elevation={8}>
              <header className={classes.header} style={isLoading ? {} : { borderTop: '4px solid #C7A763' }}>
                {isLoading && <LinearProgress color='primary' style={{ borderRadius: '5px 5px 0px 0px' }} />}
              </header>
              <main className={classes.main}>
                <Grid container alignContent='center' className={clsx(classes.successGrid)}>
                  <Grid item xs={12} className={clsx(classes.tabPanel)}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                      <Divider style={{ marginBottom: '1rem' }} />

                      <Typography variant={'h6'} gutterBottom>
                        Contact Information
                      </Typography>
                      <form
                        id='updateInfoForm'
                        onSubmit={e => {
                          e.preventDefault();
                          handleSave();
                          // SignUpProcess();
                        }}
                      >
                        <TextField
                          fullWidth
                          variant='filled'
                          required
                          placeholder='First Name'
                          label='First Name'
                          size='small'
                          className={classes.textPadding}
                          value={userInfo.first_name}
                          onChange={e =>
                            setUserInfo({
                              ...userInfo,
                              first_name: e.target.value,
                            })
                          }
                        />

                        <TextField
                          fullWidth
                          variant='filled'
                          required
                          placeholder='Last Name'
                          label='Last Name'
                          size='small'
                          className={classes.textPadding}
                          value={userInfo.last_name}
                          onChange={e =>
                            setUserInfo({
                              ...userInfo,
                              last_name: e.target.value,
                            })
                          }
                        />

                        <TextField
                          fullWidth
                          variant='filled'
                          placeholder='Email'
                          required
                          type='email'
                          label='Email'
                          size='small'
                          className={classes.textPadding}
                          value={userInfo.email}
                          onChange={e =>
                            setUserInfo({
                              ...userInfo,
                              email: e.target.value,
                            })
                          }
                        />
                        {/* {(userInfo?.countryData?.iso2 || countryData?.iso2) && (
                          <div className='IntTelInputContainer'>
                            <IntlTelInput
                              containerClassName='intl-tel-input'
                              inputClassName='MuiInputBase-input'
                              defaultCountry={userInfo?.countryData?.iso2 ?? countryData?.iso2}
                              onPhoneNumberChange={handlePhoneNumber}
                              format={true}
                              telInputProps={{
                                required: false,
                                type: 'number',
                              }}
                              value={userInfo.phone ?? ''}
                            />
                            {phone && phone.trim().length > 0 && (
                              <>
                                <Alert severity={`${isValidPhoneNumber ? 'success' : 'error'}`} className={classes.alert}>
                                  Phone number in E.164 format: {phoneFullNumber}
                                  <br />
                                </Alert>
                              </>
                            )}
                          </div>
                        )} */}
                        <TextField
                          fullWidth
                          variant='filled'
                          placeholder='Password'
                          type='password'
                          required
                          label='Password'
                          size='small'
                          value={userInfo.password}
                          onChange={e =>
                            setUserInfo({
                              ...userInfo,
                              password: e.target.value,
                            })
                          }
                          className={classes.textPadding}
                          helperText={'Password must be 6-10 characters with letters and numbers only.'}
                        />

                        <TextField
                          fullWidth
                          variant='filled'
                          placeholder='Confirm Password'
                          label='Confirm Password'
                          size='small'
                          className={classes.textPadding}
                          type='password'
                          required
                          value={userInfo.password_confirmation}
                          onChange={e =>
                            setUserInfo({
                              ...userInfo,
                              password_confirmation: e.target.value,
                            })
                          }
                        />

                        <Grid item xs={12} className={clsx(classes.bottomBtnContainer)}>
                          <Button variant='contained' type='submit' form='updateInfoForm' color='primary' disabled={isLoading} fullWidth>
                            Save
                          </Button>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              </main>
            </Paper>

            <div className={classes.bottomText}>
              <Typography variant='caption'>
                Cashier service <span dangerouslySetInnerHTML={{ __html: '&copy;' }} /> 2020-2023
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
