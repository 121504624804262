import { connect } from 'react-redux';
import { isLoadingSelector, isLoggedInSelector, loggedInUserSelector, validTokenResultSelector } from '../../../../api/user/user.selector';
import { ExternalActionProps, ExternalAuthPageComponent, ExternalProps } from './ext-auth-page.component';
import { ExternalAuthAction } from '../../../../api/user/actions/external-auth-action';
import { appSettingsSliceSelector } from '../../../../utils/redux/slice-app-settings/app-settings-selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    isLoggedIn: isLoggedInSelector(state),
    loggedInUser: loggedInUserSelector(state),
    validToken: validTokenResultSelector(state),
    app_settings: appSettingsSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  ExternalAuthAction,
};

export const ExtAuthPageContainer = connect(mapStateToProps, mapDispatchToProps)(ExternalAuthPageComponent);
