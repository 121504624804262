import { Action, createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { IExternalAuth, ILoginResponse } from '../../../models/login';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';
import { IGet } from '../../../models/get-all';
import { setLoginSession, logLoginEventInLuckyOrange } from './login-action';

const suffix = `/${userReducer.sliceName}/app`;

const EXTERNAL_AUTH = `EXTERNAL_AUTH${suffix}`;
export const ExternalAuthAction = createAction<IExternalAuth>(EXTERNAL_AUTH);

const EXTERNAL_AUTH_ERROR = `EXTERNAL_AUTH_ERROR${suffix}`;
const ExternalAuthErrorAction = createAction<string>(EXTERNAL_AUTH_ERROR);
const EXTERNAL_AUTH_SUCCESS = `EXTERNAL_AUTH_SUCCESS${suffix}`;
const ExternalAuthSuccessAction = createAction<any>(EXTERNAL_AUTH_SUCCESS);

function* ExternalAuthWatcher(): SagaIterator {
  yield takeEvery(EXTERNAL_AUTH, loginWorker);
}
mergeSaga(ExternalAuthWatcher);

function* loginWorker(action: Action<IExternalAuth>): SagaIterator {
  try {
    const response: IGet<ILoginResponse> = yield call(getApiPlayer, action.payload);
    const { data, message } = response;
    if (data.token) {
      setLoginSession(data);
      yield put(ExternalAuthSuccessAction(response.data));
      logLoginEventInLuckyOrange(response.data.username);
    } else {
      yield put(ExternalAuthErrorAction(message || 'Something went wrong.'));
    }
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(ExternalAuthErrorAction('Login failed. Please make sure your login information is correct.'));
  }
}

const getApiPlayer = (data: IExternalAuth): Request => {
  return vipApi(
    API_PATH.USER.TPI_EXTERNAL_AUTH,
    'post',
    {
      ...data,
      login_type: 3,
    },
    'json',
  );
};

const reduceHandlers = {
  [EXTERNAL_AUTH]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    isLoggedIn: false,
  }),
  [EXTERNAL_AUTH_SUCCESS]: (slice: UserSliceType, action: Action<ILoginResponse>): UserSliceType => {
    return {
      ...slice,
      loggedInUser: action.payload,
      isLoggedIn: true,
      isLoading: false,
      validToken: true,
    };
  },
  [EXTERNAL_AUTH_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    isLoggedIn: false,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
