import { Switch, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import { NotFoundPage } from './area/not-found/not-found-page.component';
import { lazily } from 'react-lazily';
import { Loading } from './shared/loading/loading.component';
import { UpdateUserProfileContainer } from './area/user-profile/update-user-profile/update-user-profile.container';
import { ExtAuthPageContainer } from './area/cashier/auth/ext-auth-page/ext-auth-page.container';
import { CognitoFlowPageContainer } from './area/cashier/cognito-flow-page/cognito-flow-page.container';

const { AdminPrivateRouteContainer } = lazily(() => import('./shared/private-route/admin-private-route.container'));
const { TransactionMethodPageContainer } = lazily(() => import('./area/cashier/transaction-method-page/transaction-method-page.container'));
const { BonusSelectPageContainer } = lazily(() => import('./area/cashier/bonus-select-page/bonus-select-page.container'));
const { TransactionInfoPageContainer } = lazily(() => import('./area/cashier/transaction-info-page/transaction-info-page.container'));
const { RequestReceivedPageContainer } = lazily(() => import('./area/cashier/request-received-page/request-received-page.container'));
const { CompletePagePageContainer } = lazily(() => import('./area/cashier/complete-page/complete-page.container'));
const { PlayerBankSelectPageContainer } = lazily(() => import('./area/cashier/player-bank-select-page/player-bank-select-page.container'));
const { CashierPageContainer } = lazily(() => import('./area/cashier/cashier-home-page/cashier.container'));
const { ConfirmTransactionPageContainer } = lazily(
  () => import('./area/cashier/confirm-transaction-page/confirm-transaction-page.container'),
);
const { LoginPageContainer } = lazily(() => import('./area/cashier/auth/login-page/login-page.container'));
const { ExtLoginPageContainer } = lazily(() => import('./area/cashier/auth/ext-login-page/ext-login-page.container'));

export const Routes = (): React.ReactElement<void> => (
  <Suspense fallback={<Loading loading={true} url_logo={null} />}>
    <Switch>
      <AdminPrivateRouteContainer path='/complete' component={CompletePagePageContainer} />
      <AdminPrivateRouteContainer path='/payment' component={RequestReceivedPageContainer} />
      <AdminPrivateRouteContainer path='/confirm' component={ConfirmTransactionPageContainer} />
      <AdminPrivateRouteContainer path='/info' component={TransactionInfoPageContainer} />
      <AdminPrivateRouteContainer path='/bonus' component={BonusSelectPageContainer} />
      <AdminPrivateRouteContainer path='/bank-select' component={PlayerBankSelectPageContainer} />
      <AdminPrivateRouteContainer path='/method' component={TransactionMethodPageContainer} />
      <AdminPrivateRouteContainer path='/update-user-profile' component={UpdateUserProfileContainer} />
      <AdminPrivateRouteContainer path='/cognito-flow' component={CognitoFlowPageContainer} />
      <AdminPrivateRouteContainer path='/cashier' component={CashierPageContainer} />
      <Route exact path='/login' component={LoginPageContainer} />
      <Route path='/ext-login' component={ExtLoginPageContainer} />
      <Route path='/ext-auth' component={ExtAuthPageContainer} />
      <Route exact path='/' component={LoginPageContainer} />
      <Route path='' component={NotFoundPage} />
    </Switch>
  </Suspense>
);
