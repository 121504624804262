import { RAFUserDto } from '../../models/RAF/raf';
import { IIdentityDialog } from '../../models/users';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'userSlice';

export interface UserSliceType {
  isLoading: boolean;
  isLoadingGetUserInfo: boolean;
  isLoadingImpersonate: boolean;
  isLoggedIn: boolean;
  isPseudoLoggedIn: boolean;
  error?: string;
  loggedInUser?: any;
  signupSuccess: boolean;
  inviteSuccess: boolean | null;
  sendOtpSuccess: boolean;
  verifyOtpSuccess: boolean;
  inviteCodeInvalid: boolean;
  inviteDetails: any;
  resetPasswordSuccess: boolean;
  userBalance: any;
  tourSteps: any;
  userHistory: any;
  bonusHistory: any;
  transactionHistory: any;
  userRaf: RAFUserDto | null;
  userRafCode: any;
  needCashierForward: boolean;
  signupUsername: string | null;
  agentData: any | null;
  verifyPhone: boolean | null;
  runTour: boolean;
  shareable_url: string | null;
  token: string | null;
  identityDialog: IIdentityDialog;
  is_verified: boolean;
  identity: string | null;
  request_default_invite_code: boolean;
  cognito_identity_result: number;
  window_opened: Window | null;
  validToken: boolean | null;
  updateProfileStatus: boolean | null;
}

const getSessionToken = () => {
  const token = localStorage.getItem('token');
  if (token !== null && token !== '') {
    return true;
  } else {
    return false;
  }
};

const getSessionUser = () => {
  const user = localStorage.getItem('user');
  if (user !== '' && user !== null) {
    return JSON.parse(user);
  } else {
    return {};
  }
};

export const initialState: UserSliceType = {
  isLoading: false,
  isLoadingGetUserInfo: false,
  isLoadingImpersonate: false,
  isLoggedIn: getSessionToken(),
  isPseudoLoggedIn: false,
  error: undefined,
  loggedInUser: getSessionUser(),
  signupSuccess: false,
  inviteSuccess: null,
  sendOtpSuccess: false,
  verifyOtpSuccess: false,
  inviteCodeInvalid: false,
  inviteDetails: {},
  resetPasswordSuccess: false,
  userBalance: {},
  tourSteps: {},
  userHistory: {},
  bonusHistory: {},
  transactionHistory: {},
  userRaf: null,
  userRafCode: null,
  needCashierForward: true,
  signupUsername: null,
  agentData: null,
  verifyPhone: null,
  runTour: false,
  shareable_url: null,
  token: null,
  identityDialog: {
    message: null,
    open: false,
    result: false,
  },
  is_verified: false,
  identity: '',
  request_default_invite_code: false,
  cognito_identity_result: -1,
  window_opened: null,
  validToken: null,
  updateProfileStatus: null,
};

export const userReducer = createAndMergeSliceReducer(sliceName, initialState);
