import { createSelector } from 'reselect';
import { userReducer, UserSliceType } from './user.reducer';

export const userSliceSelector = (state: any): any => state[userReducer.sliceName];

export const isLoadingSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.isLoading || false);

export const isLoadingGetUserInfoSelector = createSelector(
  userSliceSelector,
  (userSlice: UserSliceType) => userSlice.isLoadingGetUserInfo || false,
);

export const isLoadingImpersonateSelector = createSelector(
  userSliceSelector,
  (userSlice: UserSliceType) => userSlice.isLoadingImpersonate || false,
);

export const isLoggedInSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.isLoggedIn);

export const errorSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.error);
export const signupSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.signupSuccess);
export const inviteSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.inviteSuccess);
export const sendOtpSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.sendOtpSuccess);
export const verifyOtpSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.verifyOtpSuccess);
export const inviteCodeInvalidSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.inviteCodeInvalid);
export const loggedInUserSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.loggedInUser);
export const isPseudoLoggedInSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.isPseudoLoggedIn);
export const inviteDetailsSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.inviteDetails);
export const resetPasswordSuccessSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.resetPasswordSuccess);
export const userBalanceSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.userBalance);
export const tourStepsSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.tourSteps);
export const userHistorySelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.userHistory);
export const bonusHistorySelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.bonusHistory);
export const transactionHistorySelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.transactionHistory);
export const userRafSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.userRaf);
export const userRafCodeSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.userRafCode);
export const needCashierForwardSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.needCashierForward);
export const signupUsernameSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.signupUsername);
export const agentDataSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.agentData);
export const verifyPhoneSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.verifyPhone);
export const runTourSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.runTour);

export const shareableUrlSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.shareable_url);
export const IsSignupVerifiedSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.is_verified);

export const tokenSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.token);

export const verifyIdentityOpenSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.identityDialog);
export const requestDefaultInviteCodeSelector = createSelector(
  userSliceSelector,
  (userSlice: UserSliceType) => userSlice.request_default_invite_code,
);

export const cognitoIdentityResultSelector = createSelector(
  userSliceSelector,
  (userSlice: UserSliceType) => userSlice.cognito_identity_result,
);

export const validTokenResultSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.validToken);

export const windowOpenedSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.window_opened);

export const updateProfileStatusSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.updateProfileStatus);
