import { connect } from 'react-redux';
import { isLoadingSliceSelector } from '../../../api/cashier/cashier.selector';
import { getUserInfoAction } from '../../../api/user/actions/get-user-info.action';
import { isLoadingSelector, loggedInUserSelector } from '../../../api/user/user.selector';
import { appSettingsSliceSelector } from '../../../utils/redux/slice-app-settings/app-settings-selector';
import { CognitoFlowPageComponent, ExternalActionProps, ExternalProps } from './cognito-flow-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state) || isLoadingSelector(state),
    user: loggedInUserSelector(state),
    app_settings: appSettingsSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getUserInfoAction,
};

export const CognitoFlowPageContainer = connect(mapStateToProps, mapDispatchToProps)(CognitoFlowPageComponent);
